import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Container, Flex, HStack, Image, Text, VStack, useMediaQuery } from '@chakra-ui/react'
import { FilterSettings, PostsItem } from '@core/app/types/postsApiType'
import { Localization } from '@core/app/types/globalApiType'

import { HalfPost } from '@page/posts/components/Post/HalfPost'
import { Post } from '@page/posts/components/Post/Post'
import { AppButton } from '@core/components/AppButton'

import { useAppTheme } from '@core/utils/contexts/ThemeContext/ThemeContext'

import { IconUndo } from '@core/assets/icons/undo'
import noJobs from '@page/posts/assets/images/empty_state.svg'
import { DefaultSelect } from '@core/components/Select/Select'

interface Posts {
    data: PostsItem | undefined
    settings: FilterSettings | undefined
    reset: () => void
    onSortChange: (sortValue: string) => void
    defaultView: number
    candidateID: number
}

export const PostsList = ({ data, settings, reset, onSortChange, defaultView, candidateID }: Posts): JSX.Element => {
    const { t } = useTranslation()
    const { appTheme } = useAppTheme()
    const [localization, setLocalization] = useState<Localization>({})
    const [sortValue, setSortValue] = useState<string>(() => {
        return localStorage.getItem(`sortValue`) || 'newest' 
    })
    const [isDesktop] = useMediaQuery('(min-width: 1230px)', {
        fallback: false,
    })
    const { newSettings } = appTheme || { newSettings: { general: {} } }
    const { general } = newSettings
    const { BackgroundText } = general

    const getInitialView = (): boolean => {
        const storedView = localStorage.getItem(`isListView`) 
        return storedView !== null ? storedView === 'true' : defaultView === 0
    }

    const [isListView, setIsListView] = useState(getInitialView)

    const handleViewChange = (view: boolean) => {
        setIsListView(view)
        localStorage.setItem(`isListView`, String(view))
    }

    const handleChange = (selectedOption: any) => {
        const value = selectedOption.value
        setSortValue(value)
        onSortChange(value)
        localStorage.setItem(`sortValue`, value) 
    }

    let dataLength: number = 0
    if (data) {
        dataLength = Object.keys(data || {}).length
    }

    useEffect(() => {
        if (!appTheme) return
        if (appTheme && appTheme?.localization) {
            setLocalization(appTheme?.localization)
        }
    }, [appTheme])

    return (
        <>
            {dataLength === 0 ? (
                <Box
                    className={'PostList-wrap'}
                    w={'full'}
                    h={'full'}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    mt={'200px'}
                >
                    <Flex flexDirection={'column'} alignItems={'center'} w={'336px'}>
                        <Image src={noJobs} />
                        <Text fontSize={'20px'} fontWeight={'600'} mt={'25px'} mb={'10px'}>
                            {t('8377')}
                        </Text>
                        <Text fontSize={'14px'} fontWeight={'500'} color={'text.secondary'}>
                            {t('8378')}.
                        </Text>
                        <Text fontSize={'14px'} fontWeight={'500'} color={'text.secondary'} mt={'3px'}>
                            {t('7845')}.
                        </Text>
                        <AppButton
                            translate="no"
                            title={t('6475')}
                            leftIcon={<IconUndo w={'14'} h={'14'} fill={'#fff'} />}
                            variant={'primary'}
                            w={'184px'}
                            mt={6}
                            onClick={() => reset()}
                        />
                    </Flex>
                </Box>
            ) : (
                <Box w={{ base: 'full', xl: '800px' }}>
                    <Container maxW={{ base: 'container.sm', xl: 'container.xl' }} px={isDesktop ? 0 : 4}>
                        <Box display={'flex'} justifyContent={'space-between'} alignItems={'flex-start'} mb={5}>
                            {isDesktop && (
                                <HStack>
                                    <Text
                                        as="span"
                                        fontSize="lg"
                                        fontWeight="semibold"
                                        color={
                                            settings?.boxStyle
                                                ? 'text.dark'
                                                : `#${BackgroundText ? BackgroundText : '000000'}`
                                        }
                                    >
                                        {dataLength}
                                    </Text>
                                    <Text
                                        fontSize="sm"
                                        fontWeight="normal"
                                        textTransform="lowercase"
                                        color={
                                            settings?.boxStyle
                                                ? 'text.dark'
                                                : `#${BackgroundText ? BackgroundText : '000000'}`
                                        }
                                    >
                                        {localization[11] ? localization[11] : t('2413')}
                                    </Text>
                                </HStack>
                            )}
                            <Box maxW={{ base: 'full', xl: '320px' }} w={'100%'}>
                                {isDesktop ? (
                                    <Flex gap="16px" alignItems="center">
                                        <HStack spacing={0} borderRadius="md" overflow="hidden" bg={'#F5F5F5'}>
                                            <Box
                                                as="button"
                                                w="36px"
                                                h="36px"
                                                bg={isListView ? '#FFFFFF' : '#F5F5F5'}
                                                border={isListView ? '1px solid #CBCBCD' : 'none'}
                                                borderRadius={isListView ? 'md' : 'none'}
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                                onClick={() => handleViewChange(true)}
                                            >
                                                <svg
                                                    width="16"
                                                    height="16"
                                                    viewBox="0 0 16 16"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        clip-rule="evenodd"
                                                        d="M2.50004 5.00033C3.14437 5.00033 3.66671 4.47799 3.66671 3.83366C3.66671 3.18933 3.14437 2.66699 2.50004 2.66699C1.85571 2.66699 1.33337 3.18933 1.33337 3.83366C1.33337 4.47799 1.85571 5.00033 2.50004 5.00033ZM4.73337 3.83366C4.73337 3.50229 5.002 3.23366 5.33337 3.23366H14C14.3314 3.23366 14.6 3.50229 14.6 3.83366C14.6 4.16503 14.3314 4.43366 14 4.43366H5.33337C5.002 4.43366 4.73337 4.16503 4.73337 3.83366ZM2.50004 9.16699C3.14437 9.16699 3.66671 8.64466 3.66671 8.00033C3.66671 7.35599 3.14437 6.83366 2.50004 6.83366C1.85571 6.83366 1.33337 7.35599 1.33337 8.00033C1.33337 8.64466 1.85571 9.16699 2.50004 9.16699ZM5.33337 7.40033C5.002 7.40033 4.73337 7.66895 4.73337 8.00033C4.73337 8.3317 5.002 8.60033 5.33337 8.60033H14C14.3314 8.60033 14.6 8.3317 14.6 8.00033C14.6 7.66895 14.3314 7.40033 14 7.40033H5.33337ZM4.73337 12.167C4.73337 11.8356 5.002 11.567 5.33337 11.567H14C14.3314 11.567 14.6 11.8356 14.6 12.167C14.6 12.4984 14.3314 12.767 14 12.767H5.33337C5.002 12.767 4.73337 12.4984 4.73337 12.167ZM2.50004 13.3337C3.14437 13.3337 3.66671 12.8113 3.66671 12.167C3.66671 11.5227 3.14437 11.0003 2.50004 11.0003C1.85571 11.0003 1.33337 11.5227 1.33337 12.167C1.33337 12.8113 1.85571 13.3337 2.50004 13.3337Z"
                                                        fill={isListView ? 'black' : '#58585B'}
                                                    />
                                                </svg>
                                            </Box>
                                            <Box
                                                as="button"
                                                w="36px"
                                                h="36px"
                                                bg={!isListView ? '#FFFFFF' : '#F5F5F5'}
                                                border={!isListView ? '1px solid #CBCBCD' : 'none'}
                                                borderRadius={!isListView ? 'md' : 'none'}
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                                onClick={() => handleViewChange(false)}
                                            >
                                                <svg
                                                    width="16"
                                                    height="16"
                                                    viewBox="0 0 16 16"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        clip-rule="evenodd"
                                                        d="M3.00011 1.06641C1.93236 1.06641 1.06677 1.93199 1.06677 2.99974V5.66641C1.06677 6.73416 1.93236 7.59974 3.00011 7.59974H5.66677C6.73452 7.59974 7.60011 6.73416 7.60011 5.66641V2.99974C7.60011 1.93199 6.73452 1.06641 5.66677 1.06641H3.00011ZM2.26677 2.99974C2.26677 2.59473 2.5951 2.26641 3.00011 2.26641H5.66677C6.07178 2.26641 6.40011 2.59473 6.40011 2.99974V5.66641C6.40011 6.07142 6.07178 6.39974 5.66677 6.39974H3.00011C2.5951 6.39974 2.26677 6.07142 2.26677 5.66641V2.99974ZM3.00011 8.39974C1.93236 8.39974 1.06677 9.26532 1.06677 10.3331V12.9997C1.06677 14.0675 1.93236 14.9331 3.00011 14.9331H5.66677C6.73452 14.9331 7.60011 14.0675 7.60011 12.9997V10.3331C7.60011 9.26532 6.73452 8.39974 5.66677 8.39974H3.00011ZM2.26677 10.3331C2.26677 9.92806 2.5951 9.59974 3.00011 9.59974H5.66677C6.07178 9.59974 6.40011 9.92806 6.40011 10.3331V12.9997C6.40011 13.4047 6.07178 13.7331 5.66677 13.7331H3.00011C2.5951 13.7331 2.26677 13.4047 2.26677 12.9997V10.3331ZM8.40011 2.99974C8.40011 1.93199 9.26569 1.06641 10.3334 1.06641H13.0001C14.0679 1.06641 14.9334 1.93199 14.9334 2.99974V5.66641C14.9334 6.73416 14.0679 7.59974 13.0001 7.59974H10.3334C9.26569 7.59974 8.40011 6.73416 8.40011 5.66641V2.99974ZM10.3334 2.26641C9.92843 2.26641 9.60011 2.59473 9.60011 2.99974V5.66641C9.60011 6.07142 9.92843 6.39974 10.3334 6.39974H13.0001C13.4051 6.39974 13.7334 6.07142 13.7334 5.66641V2.99974C13.7334 2.59473 13.4051 2.26641 13.0001 2.26641H10.3334ZM10.3334 8.39974C9.26569 8.39974 8.40011 9.26532 8.40011 10.3331V12.9997C8.40011 14.0675 9.26569 14.9331 10.3334 14.9331H13.0001C14.0679 14.9331 14.9334 14.0675 14.9334 12.9997V10.3331C14.9334 9.26532 14.0679 8.39974 13.0001 8.39974H10.3334ZM9.60011 10.3331C9.60011 9.92806 9.92843 9.59974 10.3334 9.59974H13.0001C13.4051 9.59974 13.7334 9.92806 13.7334 10.3331V12.9997C13.7334 13.4047 13.4051 13.7331 13.0001 13.7331H10.3334C9.92843 13.7331 9.60011 13.4047 9.60011 12.9997V10.3331Z"
                                                        fill={!isListView ? 'black' : '#58585B'}
                                                    />
                                                </svg>
                                            </Box>
                                        </HStack>
                                        <Box minW={'230px'}>
                                            <DefaultSelect
                                                className={'max-w-[100%] post-list-select'}
                                                inputH={'36px'}
                                                placeholder={t('4125')}
                                                options={[
                                                    { label: t('7915'), value: 'newest' },
                                                    { label: t('7916'), value: 'oldest' },
                                                ]}
                                                onChange={handleChange}
                                                values={sortValue}
                                                searchable={false}
                                                isBorderOnHover={true}
                                                placeholderHoverBgColor="#F5F5F5"
                                            />
                                        </Box>
                                    </Flex>
                                ) : (
                                    <DefaultSelect
                                        className={'max-w-[100%]'}
                                        placeholder={t('4125')}
                                        options={[
                                            { label: t('7915'), value: 'newest' },
                                            { label: t('7916'), value: 'oldest' },
                                        ]}
                                        onChange={handleChange}
                                        values={sortValue}
                                        searchable={false}
                                        isBorderOnHover={true}
                                        placeholderHoverBgColor="#F5F5F5"
                                    />
                                )}
                            </Box>
                        </Box>
                    </Container>
                    <VStack w="full" gap={4}>
                        {data ? (
                            isListView ? (
                                Object.values(data).map((post: PostsItem[0]) => (
                                    <Post postData={post} key={post.AdID} />
                                ))
                            ) : (
                                <Box
                                    w="full"
                                    display="grid"
                                    gridTemplateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
                                    gap={4}
                                >
                                    {Object.values(data).map((post: PostsItem[0]) => (
                                        <HalfPost postData={post} key={post.AdID} />
                                    ))}
                                </Box>
                            )
                        ) : null}
                    </VStack>
                </Box>
            )}
        </>
    )
}
